/* .map__container {
	position: relative;
	background-color: white;
	border: 1px solid rgb(149, 196, 212);
	padding: 5px;
	padding-bottom: 10px;
	border-radius: 10px;
	margin-bottom: 5px;
} */

/* .map {
	width: 830px;
	height: 600px;
	border: 1px solid rgb(149, 196, 212);
	padding: 10px;
	padding-bottom: 5px;
	border-radius: 10px;
	margin-bottom: 10px;
} */

.location-icon {
	font-size: 3rem;
	color: deepskyblue;
}

.portfolio-icon {
	font-size: 3rem;
	color: red;
}

.mall-icon {
	font-size: 2rem;
	color: greenyellow;
}

.retailer-icon {
	align-items: center;
	font-size: 2rem;
	color: red;
}

.location-icon:hover {
	cursor: pointer;
	color: cornflowerblue;
}

.retailer-icon:hover {
	cursor: pointer;
	color: cornflowerblue;
}

.location-info {
	position: absolute;
	top: 50px;
	right: 50px;
	width: 400px;
	min-height: 120px;
	padding: 20px;
	background-color: rgba(0, 0, 0, 0.6);
	border-radius: 10px;
	font-size: 16px;
	color: aliceblue;
	text-align: center;
	align-items: center;
}

.location-info ul {
	list-style-type: none;
	padding: 0;
}

/* h1 {
	font-size: 22px;
	background-color: #fff;
	position: absolute;
	top: 1rem;
	left: 1rem;
	color:mediumblue;
	color: black;
	z-index: 10;
	padding: 5px;
	opacity: 80%;
	border-radius: 10px;
} */

.search {
	position: absolute;
	top: 1rem;
	/* left: 70%; */
	/* transform: translateX(-50%); */
	width: 190px;
	/* max-width: 400px; */
	z-index: 10;
	font-size: 16px;
}

.form__gridHolder {
	padding: 20px;
}

.form__imageHolder {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	padding: 20px;
}

.form__image {
	display: flex;
	justify-content: space-evenly;
	font-size: 12px;
	height: 180px;
	/* width: 360px; */
	margin-bottom: 10px;
	border-radius: 10px;
	border: 1px solid rgb(149, 196, 212);
}
