.card__root {
	align-items: center;
	border: 1px solid lightblue;
	background-color: blue;
	height: 280px;
	padding: 10px;
	margin-bottom: 20px;
}

.locationMedia__img {
	height: 120px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.locationMedia__imgDisplay {
	height: 160px;
	border-radius: 5px;
}

.location__name {
	font-size: 16px;
	font-weight: 600;
	color: black;
}

.location__address {
	font-size: 12px;
	font-weight: 400;
	color: gray;
}

.location__sqft {
	font-size: 14px;
	font-weight: 600;
	color: gray;
	padding-top: 10px;
}

.tab__header {
	background-color: white;
}

.details__avatar {
	width: 200px;
}

.details__avatar:hover {
	width: 220px;
	border-radius: 30px;
	cursor: pointer;
	color: black;
}

.details__avatar_noHover {
	width: 200px;
}

.locations__avatar {
	height: 150px;
	max-width: 200px;
	margin-bottom: 20px;
}

.locations__avatar:hover {
	height: 155px;
	max-width: 205px;
	border-radius: 30px;
	cursor: pointer;
	color: black;
}

.locations__avatar_noHover {
	max-width: 200px;
}
