.post {
	background-color: white;
	padding: 15px;
	margin-bottom: 15px;
	border-radius: 5px;
	/* make this a mixin */
	box-shadow: inset 0 0 0 0.0625rem #edf2f7, 0 0.125rem 0.0625rem -0.0625rem rgb(0 0 0 / 20%),
		0 0.0625rem 0.0625rem 0 rgb(0 0 0 / 14%), 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 12%);
}

.post__box {
	background-color: white;
	padding: 15px;
	margin-bottom: 10px;
	border-radius: 5px;
	/* make this a mixin */
	box-shadow: inset 0 0 0 0.0625rem #edf2f7, 0 0.125rem 0.0625rem -0.0625rem rgb(0 0 0 / 20%),
		0 0.0625rem 0.0625rem 0 rgb(0 0 0 / 14%), 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 12%);
	height: 600px;
}

.post__header {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}

.post__info {
	margin-left: 10px;
}

.post__info > p {
	font-size: 11px;
	color: rgb(18, 71, 246);
	margin: 0;
}

.post__info > p:hover {
	background-color: whitesmoke;
	border-radius: 10px;
	cursor: pointer;
	color: black;
}

.post__info > h2 {
	font-weight: 600;
	font-size: 15px;
	margin: 0;
}

.post__body {
	display: flex;
	align-items: center;
}

.post__body > p {
	font-size: 18px;
	margin: 0;
}

.post_actions {
	display: flex;
	grid-gap: 15px;
	/* margin: 5px 0 15px; */
}

.post_actions .post__body {
	flex: 1;
}

.post__comments {
	margin-bottom: 20px;
}

.post__buttons {
	display: flex;
	font-size: 12px;
	margin: 0 10px 0 0;
}

.post__likebutton svg {
	font-size: 1rem;
}

.post__likeline {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.post__buttons label {
	margin: 0;
}

.post__image {
	display: flex;
	justify-content: space-evenly;
	margin-left: -15px;
	margin-right: -15px;
	border-radius: 2px;
	/* background-color: #e2e8f0; */
	/* background-color: #252525; */
}

.post__image-img {
	height: 0;
	width: 100%;
	padding-top: 56.25%; /* 75% */
	background-size: cover;
}

.post__image-video video {
	max-height: 500px;
}
