html {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

img,
video,
svg {
	display: block;
	max-width: 100%;
}
