.sidebar {
	position: sticky;
	top: 96px;
	flex: 0.2;
	border-radius: 2px;
	text-align: center;
	height: fit-content;
	margin-bottom: 15px;
}

.sidebar__news {
	position: sticky;
	border-radius: 2px;
	text-align: center;
	height: 780px;
	margin-top: 10px;
	padding: 5px;
	border: 1px solid rgb(149, 196, 212);
	background-color: white;
	overflow-y: auto;
}

.sidebar__avatar {
	margin-bottom: 10px;
	height: 63px !important;
	width: 63px !important;
	border: 2px solid whitesmoke;
}

.sidebar__avatarPortfolio {
	margin-bottom: 10px;
	border: 2px solid whitesmoke;
}

.sidebar__avatarPortfolio > img {
	height: 63px !important;
	width: 63px !important;
	border: 2px solid whitesmoke;
}

.sidebar__top {
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px solid rgb(149, 196, 212);
	border-radius: 2px;
	background-color: white;
	margin-bottom: 15px;
	padding-bottom: 5px;
}

.sidebar__top > img {
	margin-bottom: -70px;
	width: 100%;
	height: 80px;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	object-fit: cover;
}

.sidebar__top > h2 {
	font-size: 18px;
}

.sidebar__top > h4 {
	color: gray;
	font-size: 12px;
}

.sidebar__stats {
	padding: 10px;
	margin-bottom: 10px;
	/* border: 1px solid lightblue; */
	/* border: 1px solid rgb(204, 223, 230); */
	border: 1px solid rgb(149, 196, 212);
	border-bottom: 20;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	background-color: white;
}

.sidebar__stat {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
}

.sidebar__stat > p {
	color: gray;
	font-size: 13px;
	font-weight: 600;
}

.sidebar__statNumber {
	font-weight: bold;
	color: #0a66c2 !important;
}

.sidebar__accordian {
	text-align: left;
	/* border: 1px solid lightblue; */
	/* border: 1px solid rgb(204, 223, 230); */
	border: 0px solid;
	background-color: white;
}

.sidebar__bottom {
	display: flex;
	flex-direction: column;
	/* border: 1px solid rgb(149, 196, 212); */
	background-color: white;
	margin-top: 10px;
	margin-bottom: 15px;
	border-radius: 2px;
	padding-bottom: 5px;
}

.sidebar__bottom > img {
	margin-bottom: -30px;
	width: 100%;
	height: 40px;
	/* border-radius: 10px; */
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	object-fit: cover;
}

.sidebar__bottom > h2 {
	color: white;
	font-size: 18px;
	margin-bottom: 15px;
}

.sidebar__bottom > h4 {
	color: gray;
	font-size: 12px;
}

.sidebar__bottom > p {
	font-size: 13px;
}

.sidebar__recentItem {
	display: flex;
	font-size: 14px;
	color: gray;
	font-weight: 400;
	cursor: pointer;
	margin-bottom: -10px;
	padding: 0px;
}

.sidebar__recentItem:hover {
	background-color: whitesmoke;
	border-radius: 5px;
	cursor: pointer;
	color: black;
}

.sidebar__recentPortfolio {
	display: flex;
	font-size: 13px;
	color: gray;
	font-weight: bolder;
	cursor: pointer;
	margin-bottom: 5px;
	padding: 5px;
}

.sidebar__recentPortfolio:hover {
	background-color: whitesmoke;
	border-radius: 10px;
	cursor: pointer;
	color: black;
}

.sidebar__hash {
	margin-left: 5px;
	margin-right: 10px;
}

.feed__collaboratorName {
	padding-left: 15px;
	/* font-weight: bolder; */
	font-size: 14px;
}
.rightbar {
	position: sticky;
	top: 80px;
	flex: 0.2;
	border-radius: 2px;
	text-align: center;
	height: fit-content;
}

.rightbar__avatar {
	margin-bottom: 10px;
}

.rightbar__top {
	display: flex;
	flex-direction: column;
	align-items: center;
	/* border: 1px solid rgb(149, 196, 212); */
	/* border: 1px solid rgb(79, 81, 82); */
	border-radius: 2px;
	background-color: rgb(0, 160, 255);
	color: black;
}

.rightbar__top > img {
	margin-bottom: 0px;
	width: 100%;
	height: 80px;
	padding: 0px;
	border-radius: 2px;
	object-fit: cover;
}

.rightbar__top > h2 {
	font-size: 28px;
	color: cyan;
	margin-bottom: 20px;
}

.rightbar__top > h4 {
	color: gray;
	font-size: 12px;
}

.rightbar__stats {
	padding: 10px;
	margin-bottom: 5px;
	/* border: 1px solid lightblue; */
	border: 1px solid rgb(149, 196, 212);
	/* border: 1px solid rgb(204, 223, 230); */
	border-bottom: 20;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	background-color: white;
}

.rightbar__stat {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
}

.rightbar__stat > p {
	color: gray;
	font-size: 13px;
	font-weight: 600;
}

.rightbar__statNumber {
	font-weight: bold;
	color: #0a66c2 !important;
}

.rightbar__bottom {
	text-align: left;
	padding: 10px;
	/* border: 1px solid lightblue; */
	border: 1px solid rgb(149, 196, 212);
	/* border: 1px solid rgb(204, 223, 230); */
	background-color: white;
	margin-top: 10px;
	border-radius: 10px;
}

.rightbar__bottom > p {
	font-size: 13px;
	padding-bottom: 10px;
}

.rightbar__recentItem {
	display: flex;
	font-size: 13px;
	color: gray;
	font-weight: bolder;
	cursor: pointer;
	margin-bottom: 5px;
	padding: 5px;
}

.rightbar__recentItem:hover {
	background-color: whitesmoke;
	border-radius: 10px;
	cursor: pointer;
	color: black;
}

.rightbar__hash {
	margin-left: 5px;
	margin-right: 10px;
}

.article__root {
	align-items: center;
	border: 1px solid lightblue;
	background-color: white;
	padding: 0px;
	margin-bottom: 20px;
	text-align: center;
}

.articleMedia__img {
	/* margin-bottom: -20px; */
	width: 100%;
	height: 100px;
	/* margin-bottom: 2px; */
}

.articleMedia__img:hover {
	border: 1px solid rgb(149, 196, 212);
}

.article__name {
	font-size: 14px;
	font-weight: 600;
	color: black;
	padding-bottom: 0.5em;
}

.article__description {
	font-size: 12px;
	font-weight: 400;
	color: gray;
}

.article__author {
	font-size: 12px;
	font-weight: 400;
	font-style: italic;
	color: cadetblue;
	/* padding: 1em; */
}

.sidebar__addDrop {
	display: flex;
	flex-direction: column;
	text-align: left;
	border: 1px solid rgb(149, 196, 212);
	background-color: white;
	margin-top: 0px;
	margin-bottom: 15px;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	padding-bottom: 5px;
	min-height: 10px;
	max-height: 560px;
	overflow-y: auto;
}

.sidebar__addDrop > img {
	margin-bottom: -30px;
	width: 100%;
	height: 40px;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	object-fit: cover;
}

.sidebar__addDrop > h2 {
	text-align: center;
	color: white;
	font-size: 18px;
	margin-bottom: 15px;
}

.sidebar__addDrop > h4 {
	color: gray;
	font-size: 12px;
}

.sidebar__addDrop > p {
	font-size: 13px;
}
