.appBar {
	border: 1px solid lightblue;
	border-radius: 5px;
	display: flex;
	color: gray;
	/* background-color: #3492d0 !important; */
	/* background-color: #39bae8 !important; */
	background-color: #0080ff !important;
	/* padding-left: 15px;
	padding-right: 15px; */
}

.feed_chatinput_from {
	border: 1px solid lightblue;
	border-radius: 30px;
	display: flex;
	padding: 10px;
	color: black;
	background-color: rgb(210, 242, 255);
	padding-left: 15px;
	vertical-align: text-bottom;
	width: fit-content;
	font-weight: 600;
}
.feed_chatinput_to {
	border: 1px solid lightblue;
	border-radius: 30px;
	display: flex;
	padding: 10px;
	color: gray;
	padding-left: 15px;
	vertical-align: text-bottom;
	width: fit-content;
	font-weight: 600;
}

.feed_tabBox {
	height: 840px;
	background-color: white;
	/* border: 1px solid rgb(204, 223, 230); */
	border: 1px solid rgb(149, 196, 212);
	padding: 10px;
	padding-bottom: 20px;
	border-radius: 10px;
	margin-bottom: 20px;
}

.feed_chatContainer {
	background-color: white;
	/* border: 1px solid rgb(204, 223, 230); */
	border: 1px solid rgb(149, 196, 212);
	padding: 10px;
	padding-bottom: 20px;
	border-radius: 10px;
	margin-bottom: 20px;
	height: 500px;
	vertical-align: text-bottom;
}

.feed_storeContainer {
	background-color: white;
	border: 1px solid rgb(149, 196, 212);
	border-top: 0px;
	padding: 10px;
	padding-left: 10px;
	padding-bottom: 10px;
	border-radius: 0px;
	display: flex;
	color: gray;
	margin-top: 0px;
	margin-bottom: 0px;
}

.feed_storeContainer:hover {
	background-color: aliceblue;
	border-radius: 0px;
	cursor: pointer;
	color: black;
}

.editForm__inputLabel {
	display: flex;
	font-size: 12px;
	color: rgb(27, 146, 219);
	font-weight: bolder;
	cursor: pointer;
	margin-bottom: -5px;
	padding: 5px;
}
.editForm__inputItem {
	display: flex;
	font-size: 16px;
	color: blue;
	border: 1px solid rgb(200, 220, 226);
	border-radius: 10px;

	/* font-weight: bolder; */
	cursor: pointer;
	margin-bottom: 0px;
	padding: 5px;
}

.editForm__inputItem:hover {
	background-color: whitesmoke;
	border-radius: 10px;
	cursor: pointer;
	color: black;
	font-weight: 600;
}

.feed__image {
	display: flex;
	justify-content: space-evenly;
	font-size: 12px;
	width: 573px;
	margin-bottom: 10px;
	border-radius: 10px;
}

.preview__image {
	display: flex;
	justify-content: space-evenly;
	font-size: 12px;
	max-height: 600px;
	/* width: 800px; */
	margin-bottom: 10px;
	border-radius: 10px;
}

.popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: aliceblue;

	display: flex;
	justify-content: center;
	align-items: center;
}

.popup-inner {
	position: relative;
	padding: 32px;
	width: 100%;
	max-width: 780px;
	background-color: #fff;
}

.popup-inner .close-btn {
	position: absolute;
	top: 16px;
	right: 16px;
}

.mdList__item {
	width: 100%;
	max-width: 800px;
	border: 1px solid lightblue;
	border-bottom: 0;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	background-color: white;
	padding-bottom: 10px;
}

.portfolio_listContainer {
	background-color: white;
	border: 1px solid rgb(149, 196, 212);
	/* border-top: 10px ; */
	padding: 10px;
	padding-left: 10px;
	padding-bottom: 10px;
	border-radius: 10px;
	display: flex;
	color: gray;
	margin-top: 10px;
	margin-bottom: 10px;
}

.portfolio_listContainer:hover {
	background-color: aliceblue;
	border-radius: 0px;
	cursor: pointer;
	color: black;
}

.button__return {
	display: flex;
	justify-content: right;
	align-items: right;
}

.locationMedia__imgDisplay {
	height: 80px;
	border-radius: 5px;
	align-items: center;
}

.locationMedia__select {
	align-items: center;
	cursor: pointer;
}

.location__size {
	text-align: right;
	color: green;
	font-weight: bolder;
	font-size: 16px;
}
.card {
	flex: 0.8;
	border: 1px solid rgb(149, 196, 212);
	align-items: center;
	text-align: center;
	/* max-width: 195px; */
	width: 195px;
	/* height: 280px; */
	margin-left: 15px;
	margin-bottom: 25px;
}

.card__header {
	border-bottom-color: 1px solid lightgray;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.card__header > img {
	margin-bottom: -70px;
	width: 100%;
	height: 80px;
	object-fit: cover;
}

.card__avatar {
	height: 93px !important;
	width: 93px !important;
}

.card__content > h2 {
	align-items: center;
	background-color: white;
	color: darkblue;
	font-weight: 400;
	font-size: 18px;
}

.card__content > h3 {
	align-items: center;
	background-color: white;
	color: dimgray;
	font-weight: 400;
	font-size: 16px;
}

.card__content > h4 {
	align-items: center;
	background-color: white;
	color: darkblue;
	font-weight: 400;
	font-size: 14px;
}

.card__content > h5 {
	align-items: center;
	background-color: white;
	color: darkslategray;
	font-weight: lighter;
	font-size: 14px;
}

.topPage__headerLabel {
	font-size: 26px;
	margin-left: 15px;
	cursor: pointer;
}
