.feed_Container {
	background-color: white;
	/* border: 1px solid rgb(204, 223, 230); */
	/* border: 1px solid rgb(149, 196, 212); */
	/* padding: 0px; */
	/* padding-bottom: 10px; */
	border-radius: 10px;
	/* margin-bottom: 20px; */
}

.feed__fileName {
	font-weight: bolder;
	font-size: 16px;
}

.appBar {
	border: 1px solid lightblue;
	border-radius: 5px;
	display: flex;
	color: gray;
	/* background-color: #3492d0 !important; */
	/* background-color: #39bae8 !important; */
	background-color: #0080ff !important;
	/* padding-left: 15px;
	padding-right: 15px; */
}

.appBar__button {
	padding: 0px;
	margin: 10px;
}
.appBar__header {
	border: 1px solid lightblue;
	border-radius: 5px;
	display: flex;
	color: #0080ff !important;
	background-color: white !important;
	padding: 10px;
	font-weight: normal;
	font-size: 22px;
	margin: 10px;
}

.appBar__sub {
	border: 1px solid lightblue;
	border-radius: 10px;
	display: flex;
	color: gray;
	/* background-color: #3492d0 !important; */
	/* background-color: #39bae8 !important; */
	/* background-color: #0080ff !important; */
	background-color: whitesmoke !important;
	/* padding-left: 15px;
	padding-right: 15px; */
}
