.centerSidebar {
  position: sticky;
  top: 80px;
  flex: 0.2;
  border-radius: 10px;
  text-align: center;
  height: fit-content;
}

.centerSidebar__avatar {
  margin-bottom: 10px;
}

.centerSidebar__top {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid lightblue; */
  /* border: 1px solid rgb(204, 223, 230); */
  border: 1px solid rgb(149, 196, 212);
  border-bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  padding-bottom: 10px;
}

.centerSidebar__top > img {
  margin-bottom: 5px;
  width: 100%;
  height: 160px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.centerSidebar__top > h2 {
  font-size: 18px;
}

.centerSidebar__top > h4 {
  color: gray;
  font-size: 12px;
}

.centerSidebar__stats {
  padding: 10px;
  margin-bottom: 10px;
  /* border: 1px solid lightblue; */
  /* border: 1px solid rgb(204, 223, 230); */
  border: 1px solid rgb(149, 196, 212);
  border-bottom: 20;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: white;
}

.centerSidebar__stat {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.centerSidebar__stat > p {
  color: gray;
  font-size: 13px;
  font-weight: 600;
}

.centerSidebar__statNumber {
  font-weight: bold;
  color: #0a66c2 !important;
}

.centerSidebar__bottom {
  text-align: left;
  padding: 10px;
  /* border: 1px solid lightblue; */
  border: 1px solid rgb(149, 196, 212);
  /* border: 1px solid rgb(204, 223, 230); */
  background-color: white;
  margin-top: 10px;
  border-radius: 10px;
}

.centerSidebar__bottom > p {
  font-size: 13px;
  padding-bottom: 10px;
}

.centerSidebar__recentItem {
  display: flex;
  font-size: 14px;
  color: gray;
  font-weight: 400;
  cursor: pointer;
  /* margin-bottom: 2px;
	padding: 2px; */
}

.centerSidebar__recentItem:hover {
  background-color: whitesmoke;
  border-radius: 10px;
  cursor: pointer;
  color: black;
}

.centerSidebar__hash {
  margin-left: 5px;
  margin-right: 10px;
}
