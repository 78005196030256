.company__root {
	align-items: center;
	border: 1px solid lightblue;
	background-color: white;
	height: 280px;
	padding: 10px;
	margin-bottom: 20px;
	text-align: center;
}

.companyMedia__img {
	margin-bottom: -20px;
	width: 100%;
	height: auto;
	border-radius: 5px;
	margin-bottom: 5px;
}

.company__name {
	font-size: 20px;
	font-weight: 800;
	color: black;
}

.company__description {
	font-size: 14px;
	font-weight: 400;
	color: gray;
}

.company__author {
	font-size: 8px;
	font-weight: 400;
	font-style: italic;
	color: gray;
}

/* Mui Modal */
.MuiDialog-paper {
	padding: 8px 0 24px;
}

.MuiBackdrop-root.MuiModal-backdrop {
	/* background-color: rgba(235, 235, 210, 0.5); */
	background-color: rgba(245, 245, 212, 0.3);
	/* background-color: rgba(205, 232, 252, 0.3); */
	opacity: 0.5;
}
