.feed_inputContainer {
	padding: 16px;
	background-color: white;
	margin-bottom: 20px;
	border-radius: 5px;
	overflow: hidden;
	/* make this a mixin */
	box-shadow: inset 0 0 0 0.0625rem #edf2f7, 0 0.125rem 0.0625rem -0.0625rem rgb(0 0 0 / 20%),
		0 0.0625rem 0.0625rem 0 rgb(0 0 0 / 14%), 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 12%);
}

.feed_inputContainer > h2 {
	text-align: center;
}

.feed__inputOptions {
	display: flex;
	align-items: center;
	grid-gap: 15px;
	margin-top: 15px;
}

.feed__inputOptions [data-testid='FileUploader'] {
	margin-bottom: 0;
}

.feed_input {
	border: 1px solid lightblue;
	border-radius: 30px;
	display: flex;
	padding: 10px;
	color: gray;
	padding-left: 15px;
	border-radius: 2px;
}

.feed_input > form {
	display: flex;
	width: 100%;
}

.feed_input > form > input {
	border: none;
	flex: 1;
	margin-left: 10px;
	outline-width: 0;
	font-weight: 600;
}

.feed_input > form > button {
	display: none;
}

.feed_newInput {
	border: 1px solid lightblue;
	border-radius: 30px;
	display: flex;
	padding: 10px;
	color: gray;
	padding-left: 15px;
	font-size: 16px;
	font-style: italic;
	/* color: rgb(18, 71, 246); */
}
.feed_newInput:hover {
	/* background-color: whitesmoke;
	border-radius: 5px; */
	border: 1px solid #0080fe;
	cursor: pointer;
	color: black;
}

/* 
.feed_newInput > form {
	display: flex;
	width: 70%;
}

.feed_newInput > form > input {
	border: none;
	flex: 1;
	margin-left: 10px;
	outline-width: 0;
	font-weight: 600;
}

.feed_newInput > form > button {
	display: none;
} */

.taskFeed {
	max-width: 720px;
	margin-left: auto;
	margin-right: auto;
}

.taskFeed_inputContainer {
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 16px;
	background-color: white;
	margin-bottom: 10px;
	border-radius: 5px;
	overflow: hidden;
	/* make this a mixin */
	box-shadow: inset 0 0 0 0.0625rem #edf2f7, 0 0.125rem 0.0625rem -0.0625rem rgb(0 0 0 / 20%),
		0 0.0625rem 0.0625rem 0 rgb(0 0 0 / 14%), 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 12%);
}

.taskFeed_inputContainer > h2 {
	text-align: center;
}

.taskFeed_headerText {
	text-align: left;
	flex: 1;
	margin-left: 10px;
	font-size: 24px;
	font-weight: 500;
	color: black;
}

.taskFeed_button {
	text-align: right;
	flex: 1;
	margin-left: 10px;
	font-size: 24px;
	font-weight: 500;
	color: black;
}

.taskFeed__inputOptions {
	display: flex;
	align-items: center;
	grid-gap: 15px;
	margin-top: 15px;
}

.taskFeed__inputOptions [data-testid='FileUploader'] {
	margin-bottom: 0;
}

.taskFeed_input {
	border: 1px solid lightblue;
	border-radius: 30px;
	display: flex;
	padding: 10px;
	color: gray;
	padding-left: 15px;
	border-radius: 2px;
}

.taskFeed_select {
	border: 1px solid lightblue;
	border-radius: 30px;
	display: flex;
	padding: 10px;
	color: gray;
	padding-left: 15px;
	border-radius: 2px;
	margin-top: 10px;
	align-items: center;
}

.taskFeed_input > form {
	display: flex;
	width: 100%;
}

.taskFeed_select > form {
	display: flex;
	width: 100%;
}

.taskFeed_input > form > input {
	border: none;
	flex: 1;
	margin-left: 10px;
	outline-width: 0;
	font-weight: 600;
}

.taskFeed_input > form > button {
	display: none;
}

.taskFeed_Form {
	padding: 15px;
}

.taskFeed__date {
	text-align: right;
	font-size: 12px;
	font-style: italic;
	color: rgb(18, 71, 246);
	width: 100%;
	margin-left: 10px;
	margin-top: -18px;
	padding-bottom: 5px;
	padding-right: 20px;
}

.actionList__item {
	display: flex;
	/* align-items: center;
	grid-gap: 15px; */
	margin-left: 15px;
	/* margin-bottom: -10px; */
}

/* CSS for the Rich Text Editor test */

.container {
	position: relative;
	height: 50vh;
	width: 100%;
	box-sizing: border-box;
}

.row {
	height: 450px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.editor {
	position: relative;
	height: 100%;
	width: 50%;
	display: flex;
	align-items: left;
	justify-content: center;
}

.preview {
	position: relative;
	height: 100%;
	width: 50%;
	display: flex;
	align-items: left;
	/* justify-content: center; */
	background-color: beige;
	border: 2px solid #0080fe;
	border-radius: 15px;
	padding-left: 20px;
	padding-right: 20px;
}

.editor-input {
	height: 100%;
	width: 100%;
}
