.feed__cards {
	/* flex:0.8;
     margin:  20px; */
	align-items: center;
}

.feed_comment {
	position: relative;
	border: 1.5px solid lightgray;
	border-radius: 2px;
	display: flex;
	padding: 10px;
	color: gray;
	width: 100%;
}
.feed_comment:after {
	content: '';
	position: absolute;
	right: 100%;
	top: 50%;
	margin-top: -6px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 6px 6px 6px 0;
	border-color: transparent lightgray transparent transparent;
}

.feed_avatar {
	display: flex;
	color: gray;
	/* margin-left: 25px; */
	/* margin-top: 10px; */
	/* width: 40px; */
}

.feed_chatinput_from {
	border: 1px solid lightblue;
	border-radius: 10px;
	display: flex;
	padding: 10px;
	color: black;
	background-color: rgb(210, 242, 255);
	padding-left: 15px;
	vertical-align: text-bottom;
	width: fit-content;
	font-weight: 600;
}
.feed_chatinput_to {
	border: 1px solid lightblue;
	border-radius: 10px;
	display: flex;
	padding: 10px;
	color: gray;
	padding-left: 15px;
	vertical-align: text-bottom;
	width: fit-content;
	font-weight: 600;
}

.feed_tabBox {
	height: 840px;
	background-color: white;
	/* border: 1px solid rgb(204, 223, 230); */
	border: 1px solid rgb(149, 196, 212);
	padding: 10px;
	padding-bottom: 20px;
	border-radius: 10px;
	margin-bottom: 20px;
}

.feed_chatContainer {
	background-color: white;
	/* border: 1px solid rgb(204, 223, 230); */
	border: 1px solid rgb(149, 196, 212);
	padding: 10px;
	padding-bottom: 20px;
	border-radius: 10px;
	margin-bottom: 20px;
	height: 500px;
	vertical-align: text-bottom;
}

.feed_storeContainer {
	background-color: white;
	border: 1px solid rgb(149, 196, 212);
	padding: 10px;
	padding-left: 10px;
	padding-bottom: 10px;
	border-radius: 15px;
	display: flex;
	color: gray;
	margin-bottom: 10px;
}

.feed_storeContainer:hover {
	background-color: aliceblue;
	border-radius: 15px;
	cursor: pointer;
	color: black;
}

.editForm__inputLabel {
	display: flex;
	font-size: 12px;
	color: rgb(27, 146, 219);
	font-weight: bolder;
	cursor: pointer;
	margin-bottom: -5px;
	padding: 5px;
}
.editForm__inputItem {
	display: flex;
	font-size: 16px;
	color: gray;
	border: 1px solid rgb(200, 220, 226);
	border-radius: 10px;

	/* font-weight: bolder; */
	cursor: pointer;
	margin-bottom: 0px;
	padding: 5px;
}

.editForm__inputItem:hover {
	background-color: whitesmoke;
	border-radius: 10px;
	cursor: pointer;
	color: black;
}

.feed__image {
	display: flex;
	justify-content: space-evenly;
	font-size: 12px;
	width: 573px;
	margin-bottom: 10px;
	border-radius: 10px;
}

.popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: aliceblue;

	display: flex;
	justify-content: center;
	align-items: center;
}

.popup-inner {
	position: relative;
	padding: 32px;
	width: 100%;
	max-width: 780px;
	background-color: #fff;
}

.popup-inner .close-btn {
	position: absolute;
	top: 16px;
	right: 16px;
}

.feed__detail {
	font-weight: bolder;
	font-size: 18px;
}

.feed__header > h2 {
	font-size: 20px;
	font-weight: 600;
	color: #0073e6;
	/* background-color: rgb(235, 246, 252); */
	padding-top: 10px;
	padding-left: 10px;
	padding-bottom: 10px;
	border: 1px solid lightgray;
	/* border: 1px solid rgb(149, 196, 212); */
	/* max-width: 200px; */
	border-radius: 5px;
}

.feed__detailLabel {
	font-size: 16px;
	color: #fff;
	background-color: #0073e6;
	padding-top: 10px;
	padding-left: 10px;
	padding-bottom: 10px;
	max-width: 200px;
	border-radius: 15px;
}

.feed__fileName {
	font-weight: bolder;
	font-size: 16px;
}

.feed__fileSize {
	/* text-align: right; */
	color: black;
	font-weight: 500;
	font-size: 15px;
}

.feed__icon {
	display: flex;
	align-items: center;
}

.feed__tenant {
	/* text-align: left; */
	color: black;
	font-weight: 500;
	font-size: 16px;
}

.newLocation__altimage {
	display: flex;
	justify-content: space-evenly;
	margin-bottom: 10px;
	border-radius: 10px;
	border: 1px solid rgb(149, 196, 212);
	width: 200px;
}

.newLocation__image {
	display: flex;
	justify-content: space-evenly;
	font-size: 12px;
	width: 360px;
	margin-bottom: 10px;
	border-radius: 10px;
	border: 1px solid rgb(149, 196, 212);

	/* width:200px; */
}

.newLocation__instructions {
	display: flex;
	justify-content: space-evenly;
	/* font-size: 12px;
	font-style: italic; */
}
.newLocation__instructions > h3 {
	color: #0073e6;
	font-weight: 600;
	font-size: 16px;
	/* font-style: italic; */
}

.newNote__image {
	display: flex;
	justify-content: space-evenly;
	font-size: 12px;
	width: 180px;
	margin-bottom: 10px;
	border-radius: 10px;
	/* border: 1px solid rgb(149, 196, 212); */

	/* width:200px; */
}

.location__image {
	display: flex;
	justify-content: space-evenly;
	font-size: 12px;
	width: 250px;
	margin-bottom: 10px;
	border-radius: 5px;
	border: 2px solid #0073e6;

	/* width:200px; */
}

.newLocation__imageHolder {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin-top: 16px;
}

.sidebar_inputContainer {
	text-align: center;
	background-color: white;
	/* border: 1px solid rgb(204, 223, 230); */
	border: 1px solid rgb(149, 196, 212);
	padding: 10px;
	padding-bottom: 20px;
	border-radius: 10px;
	margin-top: 20px;
}

.sidebar_inputContainer > h2 {
	text-align: center;
}

.feed__chooseFileButton {
	/* padding: 10px; */
	margin-bottom: 20px;
}

.feed__collaboratorName {
	/* font-weight: bolder; */
	padding-left: 15px;
	font-size: 14px;
}

.login_inputContainer {
	background-color: white;
	/* border: 1px solid rgb(204, 223, 230); */
	border: 1px solid rgb(149, 196, 212);
	padding: 10px;
	padding-bottom: 20px;
	border-radius: 10px;
	margin-bottom: 20px;
	width: 474px;
}

.feed__select {
	/* font-weight: bolder; */
	padding-left: 15px;
	font-size: 14px;
}

.note-image {
	height: 0;
	padding-top: 75%;
	background-size: cover;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	border: 1px solid rgb(149, 196, 212);
}

.note-image img {
	display: none;
	/*	 This is temporary. Remove this from the note.*/
}
