.note {
	padding: 16px;
	background-color: white;
	margin-bottom: 10px;
	/* make this a mixin */
	box-shadow: inset 0 0 0 0.0625rem #edf2f7, 0 0.125rem 0.0625rem -0.0625rem rgb(0 0 0 / 20%),
		0 0.0625rem 0.0625rem 0 rgb(0 0 0 / 14%), 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 12%);
	border-radius: 5px;
	overflow: hidden;
}

.note__creator {
	display: flex;
	padding-bottom: 0px;
	text-align: right;
}

.note__date {
	font-size: 12px;
	color: rgb(18, 71, 246);
	width: 100%;
	margin-left: 10px;
	padding-top: 10px;
	padding-bottom: 0px;
}

.note__header {
	display: flex;
	margin-bottom: 10px;
}

.note__info {
	width: 100%;
	margin-left: 10px;
	padding-bottom: 0px;
}

.note__info > p {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 12px;
	color: rgb(18, 71, 246);
}

.note__info > h2 {
	margin-bottom: 0;
	font-size: 13px;
	font-weight: 600;
}

.note__body {
	background-color: white;
	overflow-wrap: anywhere;
}

.note__body > h3 {
	font-size: 18px;
	color: black;
	font-weight: 400;
}
.note__body > p {
	font-size: 15px;
}

.note__body > h5 {
	font-size: 12px;
	font-style: italic;
}

.note__buttons {
	/* display:flex; */
	/* justify-content: space-evenly; */
	font-size: 12px;
}

.note__image {
	display: flex;
	/* background-color: #B9EDF8; */
	/* background-color: #cdffff; */
	/* background-color: #252525; */
	justify-content: space-evenly;
	margin: 10px;
	/* border: 1px solid rgb(149, 196, 212); */
	padding: -15px;
}
.rightbar {
	padding: 16px;
	background-color: white;
	margin-bottom: 20px;
	/* make this a mixin */
	box-shadow: inset 0 0 0 0.0625rem #edf2f7, 0 0.125rem 0.0625rem -0.0625rem rgb(0 0 0 / 20%),
		0 0.0625rem 0.0625rem 0 rgb(0 0 0 / 14%), 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 12%);
	border-radius: 5px;
	/* overflow: hidden; */
}

.rightbar__image {
	/* display: flex; */
	/* background-color: #B9EDF8; */
	/* background-color: #cdffff; */
	/* background-color: #252525; */
	/* justify-content: space-evenly; */
	margin: -10px;
	width: 420px;
	/* border: 1px solid rgb(149, 196, 212); */
	/* padding: -15px; */
}

.card__image {
	font-family: sans-serif;
	text-align: center;
	width: 100%;
	height: 560px;
	background: #445252;
	color: aliceblue;
	font-size: 30px;
	/* margin: 0 20px; */
	margin-bottom: -40px;

	display: flex;
	justify-content: center;
	align-items: center;
}

.card__note {
	font-family: sans-serif;
	text-align: center;
	width: 100%;
	color: black;
	/* background: blanchedalmond; */
	font-size: 18px;
	/* margin: 30px; */
	display: flex;
	justify-content: center;
	align-items: center;
}

.task {
	padding-left: 16px;
	padding-right: 16px;
	background-color: white;
	margin-bottom: 20px;
	/* make this a mixin */
	box-shadow: inset 0 0 0 0.0625rem #edf2f7, 0 0.125rem 0.0625rem -0.0625rem rgb(0 0 0 / 20%),
		0 0.0625rem 0.0625rem 0 rgb(0 0 0 / 14%), 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 12%);
	border-radius: 5px;
	overflow: hidden;
}

.task__header {
	display: flex;
	/* margin-bottom: 10px; */
}

.task__info {
	display: flex;
	text-align: left;
	width: 75%;
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 10px;
	/* background-color: yellow; */
}

.task__info > h2 {
	font-size: 16px;
	font-weight: 600;
	padding-left: 10px;
}

.task__info > h2:hover {
	font-size: 17px;
	font-weight: 600;
	padding-left: 10px;
}

.task__info > p {
	font-size: 12px;
	color: rgb(18, 71, 246);
}

.task__icon {
	size: 35px;
	padding-top: 5px;
}

.task__button {
	/* padding-top: 5px; */
	font-size: 15px;
	color: rgb(18, 71, 246);
	text-align: right;
	width: 25%;
}

.task__date {
	font-size: 15px;
	color: rgb(18, 71, 246);
	text-align: right;
	width: 100%;
}

.task__info > h2:hover {
	font-size: 17px;
	font-weight: 600;
	padding-left: 10px;
}

.task__info > h3 {
	font-size: 12px;
	font-weight: 600;
	padding-right: 10px;
	color: rgb(18, 71, 246);
}

.task__body {
	background-color: white;
	overflow-wrap: anywhere;
}

.task__body > h3 {
	font-size: 18px;
	color: black;
	font-weight: 400;
}
.task__body > p {
	font-size: 18px;
}

.task__body > h5 {
	font-size: 12px;
	font-style: italic;
}

.task__info:hover {
	cursor: pointer;
	font-size: 18px;
	color: blue;
}
