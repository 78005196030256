.inputOption {
  display: flex;
  align-items: center;
  margin-top: 15px;
  color: gray;
  padding: 10px;
  cursor: pointer;
}

.inputOption:hover {
  background-color: whitesmoke;
  border-radius: 10px;
  cursor: pointer;
  color: black;
}

.inputOption > h4 {
  margin-left: 5px;
  font-size: 14px;
  font-weight: 500;
}
