.card {
	flex: 0.8;
	border: 1px solid rgb(149, 196, 212);
	align-items: center;
	text-align: center;
	max-width: 195px;
	height: 280px;
	margin-left: 15px;
	margin-bottom: 25px;
}

.card__header {
	/* border-bottom-color: 1px solid lightgray; */
	display: flex;
	flex-direction: column;
	align-items: center;
}

.card__header > img {
	margin-bottom: -70px;
	width: 100%;
	height: 80px;
	object-fit: cover;
}

.card__avatar {
	height: 93px !important;
	width: 93px !important;
}

.card__content > h2 {
	align-items: center;
	background-color: white;
	color: darkblue;
	font-weight: 400;
	font-size: 18px;
}

.card__content > h3 {
	align-items: center;
	background-color: white;
	color: dimgray;
	font-weight: 400;
	font-size: 16px;
}

.card__content > h4 {
	align-items: center;
	background-color: white;
	color: darkblue;
	font-weight: 400;
	font-size: 14px;
}

.card__content > h5 {
	align-items: center;
	background-color: white;
	color: darkslategray;
	font-weight: lighter;
	font-size: 14px;
}
