.card__root {
	align-items: center;
	border: 1px solid lightblue;
	background-color: blue;
	height: 280px;
	padding: 10px;
	margin-bottom: 20px;
}

.locationMedia__img {
	height: 120px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.locationMedia__imgDisplay {
	height: 160px;
	border-radius: 5px;
}

.icon__fileTypePdf {
	color: red;
}

.icon__fileTypeRed {
	color: red;
}

.icon__fileTypeCSV {
	color: green;
}

.icon__fileTypeGreen {
	color: green;
}

.icon__fileTypeExcel {
	color: green;
}

.icon__fileTypeJpeg {
	color: orange;
}

.icon__fileTypeWord {
	color: blue;
}

.icon__fileTypeBlue {
	color: blue;
}

.icon__fileTypeUnknown {
	color: black;
}

.location__address {
	font-size: 12px;
	font-weight: 400;
	color: gray;
}

.location__sqft {
	font-size: 14px;
	font-weight: 600;
	color: gray;
	padding-top: 10px;
}

.tab__header {
	background-color: white;
}

.icon__listElement {
	size: 3em;
	color: blue;
}

.feed__fileName {
	font-weight: bolder;
	font-size: 16px;
}
